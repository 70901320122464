.App {
  margin: 2rem;
}

.containerTest {
  margin: 8px;
  padding: 16px;
}

.containerTest-header {
  display: flex;
  align-items: center;
  color: darkgray;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
}

.containerTest-state {
  margin-right: 8px;
  padding: 4px;
  color: white;
}

.containerTest-state-success {
  background-color: #5ece33;
}

.containerTest-state-fail {
  background-color: #ff0d16;
}

.containerTest-state-running {
  background-color: #6092d4;
}

.containerTest-state-orange {
  background-color: #ffab00;
}
